.form-iterator {
  section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 600px;

    & > div {
      flex-basis: 50%;
      box-sizing: border-box;
      padding: 5px;
    }

    @media (max-width: 800px) {
      max-width: 100%;

      & > div {
        flex-basis: 100%;

        & > div {
          width: 100%;
        }
      }
    }
  }

  span {
    margin-left: auto;
  }
}

.total-score {
  &_error {
    color: red;
  }
}

.form-data-consumer {
  section {
    display: grid;
    grid-template-columns: 1fr 300px;
    row-gap: 15px;
    max-width: 600px;
  }
}
